<template>
  <div id="sfu-generate">
    <div class="container mx-auto">
      <div class="form-wrapper">
        <b-row class="align-items-center">
          <b-col cols="12" lg>
            <div class="slogan text-center text-lg-left">
              Введите название комнаты и нажмите создать конференцию
            </div>
          </b-col>
          <b-col lg="4" col class="mt-3 mt-lg-0">
            <b-input class="conf-input" v-model="room"></b-input>
          </b-col>
          <b-col cols="auto" class="mt-3 mt-lg-0">
            <b-button variant="conf"
                      @click="generateRoom">
              <RefreshSvg/>
            </b-button>
          </b-col>
          <b-col lg="auto" cols="12" class="mt-3 mt-lg-0">
            <b-button variant="conf"
                      class="w-100"
                      @click="showModal = true">
              Создать конференцию
            </b-button>
          </b-col>
        </b-row>
      </div>
    </div>
    <b-modal v-model="showModal"
             id="conf-modal"
             dialog-class="conf-modal"
             hide-header
             hide-footer>
      <h3 class="text-center">Вход в видеоконференцию</h3>
      <b-button @click="showModal = false"
                variant="text"
                class="close-btn p-0">
        <CloseSvg/>
      </b-button>
      <div class="mt-5">Скопируйте  и скиньте ссылку участникам видеоконференции</div>
      <div class="link-wrapper mt-3">{{ studentLink }}</div>
      <div class="text-right mt-3">
        <b-button @click="copy" variant="text" class="btn-copy">копировать</b-button>
      </div>
      <div class="text-center mt-5">
        <b-button @click="startConference" class="btn-enter">Войти</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import RefreshSvg from "@/assets/svg/refresh2.svg"
import CloseSvg from "@/assets/svg/close_sfu.svg"
import { uuid } from 'vue-uuid'

export default {
  name: "SFUGenerate",
  metaInfo() {
    return {
      title: 'Создать конференцию',
    }
  },
  components: {
    RefreshSvg,
    CloseSvg
  },
  data() {
    return {
      room: '',
      showModal: false
    }
  },
  computed: {
    hostLink() {
      return this.generateLink(true)
    },
    studentLink() {
      return this.generateLink(false)
    }
  },
  methods: {
    generateRoom() {
      this.room = uuid.v4().slice(0, 18)
    },
    startConference() {
      window.open(this.hostLink, '_blank')
    },
    generateLink(host = false) {
      const userName = host ? 'Teacher' : 'Student'
      let params = {
        room: this.room,
        timestamp: Date.now(),
        host,
        userId: uuid.v4(),
        name: userName,
        callType: 'quickcall',
      }
      params = new URLSearchParams(params).toString()
      return `https://sfu-vpc.study.enggo.kz/${btoa(params)}`
    },
    copy() {
      let _this = this
      navigator.clipboard.writeText(this.studentLink).then(function() {
        _this.$success(_this.$t('copied'))
      }, function(err) {
        console.log(err)
      });
    },
  },
  mounted() {
    this.generateRoom()
  }
}
</script>

<style scoped lang="scss">

@font-face {
  font-family: 'Nunito';
  src: url('/fonts/Nunito-ExtraBold.ttf');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('/fonts/Nunito-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

#sfu-generate {
  padding: 15px;
  font-family: 'Nunito';
  .container {
    margin: 100px 0;
  }
  .form-wrapper {
    padding: 49px 40px;
    color: #fff;
    background: #00E5ff;
    border-radius: 15px;
  }
  .btn-conf {
    background: #fff;
    border-radius: 15px;
    color: #00E5FF;
    font-weight: 800;
    font-size: 18px;
    padding: 0 21px;
  }
  .slogan {
    font-weight: 800;
    font-size: 22px;
  }
  .conf-input, .btn-conf {
    height: 56px;
  }
  .conf-input {
    font-size: 16px;
    text-align: center;
    background: #fff;
    font-weight: 500;
    color: #94A0B1;
    padding: 0 20px;
    line-height: 56px;
  }
}
::v-deep .conf-modal {

  .modal-content {
    border-radius: 15px;
    padding: 24px;
    h3 {
      padding-top: 10px;
    }
    .close-btn {
      position: absolute;
      right: 0;
      top: 0;
    }
    .link-wrapper {
      background: #E6F7FF;
      border-radius: 15px;
      word-break: break-all;
      padding: 24px;
    }
    .btn-copy {
      color: #00E5FF;
      font-weight: 800;
      background: #fff;
      border: 1px solid #00E5FF;
      border-radius: 15px;
      font-size: 16px;
      height: 50px;
    }
    .btn-enter {
      border-radius: 15px;
      background: #00E5FF;
      border: none;
      font-weight: 800;
      padding: 0 40px;
      height: 56px;
    }
  }
}

@media(max-width: 768px) {
  #sfu-generate {
    .form-wrapper {
      padding: 36px 24px;
    }
    .slogan {
      font-size: 17px;
    }
    .btn-conf {
      font-size: 17px;
    }
    .conf-input {
      font-size: 15px;
    }
    .conf-input, .btn-conf {
      height: 52px;
    }
  }
  ::v-deep .conf-modal {
    .modal-content {
      padding: 18px;
    }
  }
}
</style>
